import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Box from "@mui/material/Box";
import { modifyVPS, addVPS } from "../../domain/apis/vpsService";
import { getServers } from "../../domain/apis/serverService";
import _ from "lodash";
import { useEffect } from "react";
import { FormUIItem } from "../commons";
import Row from "react-bootstrap/Row";
import FormControl from "@mui/material/FormControl";
import Col from "react-bootstrap/Col";
import { useState } from 'react';
import { ComboBox } from '../commons/Combobox';
import { styles } from './styles';

const defaultState = {
  core: 4,
  ram: 4,
  storage: 80,
};

function VPSModifyComponent({ show, handleClose, data }) {
  const [servers, setServers] = useState([]);
  const [formUI, setFormUI] = useState({});
  const [error, setError] = useState({});

  function fetchServers() {
    getServers().subscribe({
      next({ data }) {
        const { value } = data;
        setServers(value);
      },
      error(err) {
        console.log(err);
      },
    });
  }

  useEffect(() => {
    setError({});
  }, [show]);

  useEffect(() => {
    fetchServers();

    if (!data) {
      setFormUI(defaultState);
    } else {
      setFormUI(data);
    }
  }, [data, show]);

  const handleUpdateForm = (key, value) => {
    let obj = {};
    obj[key] = value;
    setFormUI({
      ...formUI,
      ...obj,
    })
  };

  const validateForm = () => {
    var result = true;
    var errorState = {};
    if (!formUI["serverID"] || formUI["serverID"] === null || formUI["serverID"] === 'none') {
      result = false;
      errorState.server = true;
    }

    if (!formUI["name"] || formUI["name"] === '') {
      result = false;
      errorState.name = true;
    }

    setError(errorState);
    return result;
  }

  const onModify = () => {
    if (!validateForm()) {
      return;
    }

    if (data) {
      modifyVPS(formUI).subscribe({
        next: ({ data }) => {
          handleClose(true);
        },
      });
      return;
    }

    addVPS(formUI).subscribe({
      next: ({ data }) => {
        handleClose(true);
      },
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => handleClose()}
        centered
        style={{ zIndex: 10000 }}
      >
        <Modal.Header>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            {data && <Modal.Title>Chỉnh sửa VPS</Modal.Title>}
            {!data && <Modal.Title>Thêm VPS</Modal.Title>}
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <FormControl fullWidth sx={{ m: 1 }}>
                <Row>
                  <Col className="col-6 col-md-4" style={{ ...styles.selfCenter, ...styles.textNormal }}>Máy chủ</Col>
                  <Col className="col-12 col-sm-6 col-md-8">
                    <ComboBox
                      value={formUI["serverID"]}
                      error={_.get(error, "server")}
                      errorMessage={"Vui lòng chọn nội dung!"}
                      data={servers}
                      placeHolder={"--- Chọn máy chủ ---"}
                      onItemChange={(value) => {
                        handleUpdateForm("serverID", value);
                      }} />
                  </Col>
                </Row>
              </FormControl>

              <FormUIItem
                label={"Tên"}
                code={"name"}
                error={_.get(error, "name")}
                errorMessage={"Vui lòng nhập nội dung!"}
                required
                initValue={_.get(data, "name")}
                handleUpdate={handleUpdateForm}
              />
              <FormUIItem
                label={"Mô tả"}
                code={"description"}
                initValue={_.get(data, "description")}
                handleUpdate={handleUpdateForm}
              />
              <FormUIItem
                label={"VMID"}
                code={"vmid"}
                initValue={_.get(data, "vmid")}
                handleUpdate={handleUpdateForm}
              />
              <FormUIItem
                label={"VM Alias"}
                code={"vmAlias"}
                initValue={_.get(data, "vmAlias")}
                handleUpdate={handleUpdateForm}
              />
              <FormUIItem
                label={"CPU CORE"}
                code={"core"}
                unit={"core"}
                type={"number"}
                initValue={_.get(data, "core") ?? defaultState.core}
                handleUpdate={handleUpdateForm}
              />
              <FormUIItem
                label={"RAM"}
                code={"ram"}
                unit={"GB"}
                type={"number"}
                initValue={_.get(data, "ram") ?? defaultState.ram}
                handleUpdate={handleUpdateForm}
              />
              <FormUIItem
                label={"Ổ đĩa cứng"}
                code={"storage"}
                unit={"GB"}
                type={"number"}
                initValue={_.get(data, "storage") ?? defaultState.storage}
                handleUpdate={handleUpdateForm}
              />
              <FormUIItem
                label={"IP"}
                code={"ip"}
                initValue={_.get(data, "ip")}
                handleUpdate={handleUpdateForm}
              />
              <FormUIItem
                label={"Băng thông"}
                code={"bandwidth"}
                unit={"MBps"}
                type={"number"}
                initValue={_.get(data, "bandwidth")}
                handleUpdate={handleUpdateForm}
              />
            </Box>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Hủy
          </Button>
          <Button variant="primary" onClick={() => onModify()}>
            Lưu
          </Button>
        </Modal.Footer>
      </Modal >
    </>
  );
}

export default VPSModifyComponent;
