import axios from "axios";
import { getCookie, removeCookie, setCookie } from "../CookieUtils";
import { requestRefreshToken } from "../../domain/apis/authService";

export const initDefault = () => {
  axios.defaults.baseURL = "https://api.system.cmate.vn";
  // Add a request interceptor
  axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      let t = getCookie("t");
      config.headers = { Authorization: `Bearer ${t}`, ...config.headers };
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      if (error.status === 401) {
        try {
          let t = getCookie("t");
          let rt = getCookie("rt");
          const rfResponse = await requestRefreshToken({
            accessToken: t,
            refreshToken: rt,
          });
          const { accessToken, refreshToken } = rfResponse.data.data;
          setCookie("t", accessToken);
          setCookie("rt", refreshToken);

          originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
          return axios(originalRequest); // Retry the original request with the new access token.
        } catch (ex) {
          console.log(ex);
          removeCookie("t");
          const baseURL =
            window.location.protocol + "//" + window.location.host;
          window.location.href = baseURL;
        }
      }
      return Promise.reject(error);
    }
  );
};
