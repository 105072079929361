import Axios from "axios";
import { bodyHeader, $observable } from "../../common/libs/AxiosObservable";

export const getNetworks = () => {
  return $observable(Axios.get("api/v1/admin/networks"));
};

export const getNetwork = (id) => {
  return $observable(Axios.get(`api/v1/admin/networks/${id}`));
};

export const deleteNetwork = (id) => {
  return $observable(Axios.delete(`api/v1/admin/networks/${id}`));
};

export const addNetwork = (obj) => {
  return $observable(
    Axios.post(`api/v1/admin/networks`, JSON.stringify(obj), {
      headers: bodyHeader,
    })
  );
};

export const modifyNetwork = (obj) => {
  return $observable(
    Axios.patch(`api/v1/admin/networks`, JSON.stringify(obj), {
      headers: bodyHeader,
    })
  );
};
