import Axios from "axios";
import {
  bodyHeader,
  $observable,
} from "../../common/libs/AxiosObservable";

export const getReceipts = () => {
  return $observable(Axios.get("api/v1/admin/receipts"));
};

export const getReceipt = (id) => {
  return $observable(Axios.get(`api/v1/admin/receipts/${id}`));
};

export const deleteReceipt = (id) => {
  return $observable(Axios.delete(`api/v1/admin/receipts/${id}`));
};

export const addReceipt = (obj) => {
  return $observable(
    Axios.post(`api/v1/admin/receipts`, JSON.stringify(obj), {
      headers: bodyHeader,
    })
  );
};

export const modifyReceipt = (obj) => {
  return $observable(
    Axios.patch(`api/v1/admin/receipts`, JSON.stringify(obj), {
      headers: bodyHeader,
    })
  );
};
