import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Box from "@mui/material/Box";
import { modifyCustomer, addCustomer } from "../../domain/apis/customerService";
import _ from "lodash";
import { useEffect, useState } from "react";
import { FormUIItem } from '../commons';
import { getOrganizations } from "../../domain/apis/organizationService";
import { styles } from './styles';
import Row from "react-bootstrap/Row";
import FormControl from "@mui/material/FormControl";
import Col from "react-bootstrap/Col";
import { ComboBox } from '../commons/Combobox';

function CustomerModifyComponent({ show, handleClose, data }) {
  const [formUI, setFormUI] = useState({});
  const [error, setError] = useState({});
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    fetchOrganizations();
  }, []);

  useEffect(() => {
    setError({});
  }, [show]);

  useEffect(() => {
    if (!data) {
      setFormUI({});
    } else {
      setFormUI(data);
    }
  }, [data, show]);

  const handleUpdateForm = (key, value) => {
    let obj = {};
    obj[key] = value;
    setFormUI({
      ...formUI,
      ...obj,
    })
  };

  const validateForm = () => {
    var result = true;
    var errorState = {};

    if (!formUI["organizationID"] || formUI["organizationID"] === null || formUI["organizationID"] === 'none') {
      result = false;
      errorState.organizationID = true;
    }

    if (!formUI["fullName"] || formUI["fullName"] === '') {
      result = false;
      errorState.fullName = true;
    }

    if (!formUI["email"] || formUI["email"] === '') {
      result = false;
      errorState.email = true;
    }

    setError(errorState);
    return result;
  }

  function fetchOrganizations() {
    getOrganizations().subscribe({
      next({ data }) {
        const { value } = data;
        setOrganizations(value);
      },
      error(err) {
        console.log(err);
      },
    });
  }

  const onModify = () => {
    if (!validateForm()) {
      return;
    }

    if (data) {
      modifyCustomer(formUI).subscribe({
        next: ({ data }) => {
          handleClose(true);
        },
      });
      return;
    }
    addCustomer(formUI).subscribe({
      next: ({ data }) => {
        handleClose(true);
      },
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => handleClose()}
        centered
        style={{ zIndex: 10000 }}
      >
        <Modal.Header>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            {data && <Modal.Title>Chỉnh sửa khách hàng</Modal.Title>}
            {!data && <Modal.Title>Thêm khách hàng</Modal.Title>}
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <FormControl fullWidth sx={{ m: 1 }}>
                <Row>
                  <Col className="col-6 col-md-4" style={{ ...styles.selfCenter, ...styles.textNormal }}>Tổ chức *</Col>
                  <Col className="col-12 col-sm-6 col-md-8">
                    <ComboBox
                      value={formUI["organizationID"]}
                      error={_.get(error, "organizationID")}
                      errorMessage={"Vui lòng chọn nội dung!"}
                      data={organizations}
                      placeHolder={"--- Chọn Tổ chức ---"}
                      onItemChange={(value) => {
                        handleUpdateForm("organizationID", value);
                      }} />
                  </Col>
                </Row>
              </FormControl>
              <FormUIItem
                label={"Họ tên"}
                code={"fullName"} required
                error={_.get(error, "fullName")}
                errorMessage={"Vui lòng chọn nội dung!"}
                initValue={_.get(data, "fullName")}
                handleUpdate={handleUpdateForm} />
              <FormUIItem
                label={"Email"}
                error={_.get(error, "email")}
                required
                errorMessage={"Vui lòng chọn nội dung!"}
                code={"email"}
                initValue={_.get(data, "email")}
                handleUpdate={handleUpdateForm} />
              <FormUIItem
                label={"Chức vụ"}
                code={"position"}
                initValue={_.get(data, "position")}
                handleUpdate={handleUpdateForm} />
            </Box>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Hủy
          </Button>
          <Button variant="primary" onClick={() => onModify()}>
            Lưu
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CustomerModifyComponent;
